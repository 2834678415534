import React, { useEffect } from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import { images } from '../../../assets'
import ReactPlayer from 'react-player/youtube'
// import './style.scss'

const {
  flStudioAudioInput,
  flStudioFxSlot,
  flStudioMidiInput,
  flStudioTranskrV2,
  flStudioChannel
} = images

const flStudioUserGuideView = () => {
	window.gtag_sendEvent('user_guide/FL_Studio')
  window.fbq_customEvent('user_guide/FL_Studio')
}

const FLStudioUserGuide = () => {
  useEffect(() => flStudioUserGuideView(), [])

  return (
    <section id='user-guide'>
      <Container className='user-guide-text'>
        <h2>FL Studio</h2>
        <p>
          The screenshots were taken on a Windows PC, yet the steps are essentially the same on a Mac.
        </p>

        <p>
          <strong>Step 1</strong>
        </p>
        <p>
          Open the audio settings (F10) and select your audio interface as the audio device. 
          We recommend using the drivers from the manufacturer, as they are most reliable and allow for the lowest latency. 
          Set the sampling rate to 48 kHz and the buffer size to 128 samples. 
          Going below 128 is not recommended, because this may result in underruns and negatively impact audio quality.
        </p>
        <Row className='justify-content-md-center'>
          <Col lg={8} className='text-center'>
            <Image className='pb-4' src={flStudioAudioInput} alt='Audio Device' fluid />
          </Col>
        </Row>

        <p>
          <strong>Step 2</strong>
        </p>
        <p>
          Open the MIDI settings (F10) and enable loopMIDI Port or IAC Driver Bus, 
          which should be listed under MIDI inputs.
        </p>
        <Row className='justify-content-md-center'>
        <Col lg={10} className='text-center'>
          <Image className='pb-4' src={flStudioMidiInput} alt='MIDI Input' fluid />
          </Col>
        </Row>

        <p>
          <strong>Step 3</strong>
        </p>
        <p>
          Select a free mixer track, 
          choose the mono input from your audio interface with your mic or instrument connected as the external audio input source 
          and insert Transkr V2 into an empty effects slot.
        </p>
        <Row className='justify-content-md-center'>
        <Col lg={8} className='text-center'>
          <Image className='pb-4' src={flStudioFxSlot} alt='Mixer FX Slot' fluid />
          </Col>
        </Row>

        <p>
          <strong>Step 4</strong>
        </p>
        <p>
          Open the channel rack and add a synthesiser or any virtual instrument of your choice for audio output. 
          The selected instrument will receive MIDI data from Transkr V2 via the MIDI port.
        </p>
        <Row className='justify-content-md-center'>
        <Col lg={8} className='text-center'>
          <Image className='pb-4' src={flStudioChannel} alt='Virtual Instrument' fluid />
          </Col>
        </Row>

        <p>
          <strong>Step 5</strong>
        </p>
        <p>
          Open Transkr V2 and
        </p>
        <ul>
          <li>
            set the line input to the channel associated with your audio input (only relevant for stereo input)
          </li>
          <ul>
            <li>
              Make sure the VU meter reacts to your input and reaches 100% without saturating.
            </li>
            <li>
              Use the gain knob on your audio interface and/or the plug-in to adjust the input level.
            </li>
            <li>
              Use the &quot;pass thru&quot; button to verify that your input is free of clicks and pops.
            </li>
          </ul>
          <li>
            set the MIDI output to the MIDI port that shall receive MIDI data from the plug-in
          </li>
          <li>
            select one of the defaults from the preset list that best represents your instrument
          </li>
        </ul>
        <Row className='justify-content-md-center'>
        <Col lg={8} className='text-center'>
          <Image className='pb-4' src={flStudioTranskrV2} alt='Transkr V2 GUI' fluid />
          </Col>
        </Row>

        <p>
          You should now be all set to start using Transkr V2 in FL Studio.
        </p>

        <p>
          <strong>See also</strong>
        </p>
        <Row>
          <Col className='yt-player'>
            <ReactPlayer url='https://youtu.be/OlFW4XlQD48' />
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default FLStudioUserGuide
