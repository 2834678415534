import React, { useMemo } from 'react'
import { Carousel, Col, Container, Image, Row } from 'react-bootstrap'
// import Slider from "react-slick";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleLeft, faCircleRight } from '@fortawesome/free-solid-svg-icons'
import { images } from '../../../assets'
// import 'slick-carousel/slick/slick.css'
// import 'slick-carousel/slick/slick-theme.css'
import './style.scss'

const {
	pianoRollBeats,
	pianoRollNotes,
	pianoRollChords,
	pianoRollMelody
} = images

const slides = [{
  id: 0,
  label: 'Metre and Tempo',
  // text: 'See temporal structure as a recurring pattern of strong and weak beats divided into duples and triples.',
	image: pianoRollBeats
}, {
  id: 1,
  label: 'Pitch, Duration and Loudness',
  // text: 'See pitch of musical tones ordered on a scale from low to high, along with their duration and loudness.',
	image: pianoRollNotes
}, {
  id: 2,
  label: 'Chords and Chord Progressions',
  // text: 'See individual pitches and their relationships that make up chords and larger chord progressions.',
	image: pianoRollChords
}, {
  id: 3,
  label: 'Main Melody and Bassline',
  // text: 'See musical tones of the main melody and the bassline that combine to form a biphonic texture.',
	image: pianoRollMelody
}];

/*
const PrevArrow = (props) => {
  const { className, style, onClick } = props
  return (
    <div className={className} style={{ ...style }} onClick={onClick}>
			<FontAwesomeIcon icon={faCircleLeft} />
		</div>
  )
}

const NextArrow = (props) => {
  const { className, style, onClick } = props
  return (
    <div className={className} style={{ ...style }} onClick={onClick}>
			<FontAwesomeIcon icon={faCircleRight} />
		</div>
  )
}
*/

const TranskrV2Carousel = () => {
	/*
	const settings = {
    fade: true,
		autoplay: true,
		autoplaySpeed: 7000,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    waitForAnimate: false,
		pauseOnHover: true,
    arrows: true,
		dots: true,
		dotsClass: 'slick-dots slick-thumb',
		customPaging: (index) => {
			return <Image src={slides[index].thumb} thumbnail />
    },
    prevArrow: <PrevArrow />,
		nextArrow: <NextArrow />,
		lazyLoad: true
  }

	const sliderItems = useMemo(() => slides.map(slide =>
		<div key={slide.id}>
			<div>
				<h3>
					{slide.label}
				</h3>
				<p>
					{slide.text}
				</p>
			</div>
      <Image src={slide.image} fluid />
    </div>
  ), [])

	return (
		<section id='transkr-v2-carousel'>
			<Container>
				<Row>
					<Col lg={10} md={10} className='m-auto slider-container'>
						<Slider {...settings}>
							{sliderItems}
						</Slider>
					</Col>
				</Row>
			</Container>
		</section>
	)
	*/

	const carouselItems = useMemo(() => slides.map(slide =>
		<Carousel.Item key={slide.id}>
      <Image src={slide.image} fluid />
			<Carousel.Caption>
				<h3>
					{slide.label}
				</h3>
				{/*
				<p>
					{slide.text}
				</p>
				*/}
			</Carousel.Caption>
		</Carousel.Item>
  ), [])

	return (
		<section id='transkr-v2-carousel'>
			<Container>
				<Row>
					<Col lg={10} md={10} className='m-auto carousel-container'>
						<div className='carousel-title'>
							<h3>File Conversion</h3>
							<p>Decompose a sound recording and gain access to musical structure without studying music theory</p>
						</div>						
						<Carousel
							fade
							pause='hover'
							prevIcon={<FontAwesomeIcon icon={faCircleLeft} />}
							nextIcon={<FontAwesomeIcon icon={faCircleRight} />}
						>
							{carouselItems}
						</Carousel>
					</Col>
				</Row>
			</Container>
		</section>
	)
}

export default TranskrV2Carousel
