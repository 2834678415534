import React, { useEffect } from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import { images } from '../../../assets'
// import './style.scss'

const {
  proToolsPlaybackEngine,
  proToolsMidiInput,
	proToolsCreateAudioTrack,
  proToolsAudioTrack,
	proToolsCreateInstrumentTrack,
	proToolsInstrumentTrack,
  proToolsInstrumentTrackInput,
	proToolsPitchPerfekt
} = images

const proToolsUserGuideView = () => {
	window.gtag_sendEvent('user_guide/Pro_Tools')
  window.fbq_customEvent('user_guide/Pro_Tools')
}

const ProToolsUserGuide = () => {
  useEffect(() => proToolsUserGuideView(), [])

  return (
    <section id='user-guide'>
      <Container className='user-guide-text'>
        <h2>Pro Tools</h2>
        <p>
          The screenshots were taken on a Windows PC, yet the steps are essentially the same on a Mac.
        </p>

        <p>
          <strong>Step 1</strong>
        </p>
        <p>
          Open the Playback Engine under Setup and select your audio interface as the audio device. 
          We recommend using the drivers from the manufacturer, as they are most reliable and allow for the lowest latency. 
          Set the sampling rate to 48 kHz and the buffer size to 128 samples. 
          Going below 128 is not recommended, because this may result in underruns and negatively impact audio quality.
        </p>
        <Row className='justify-content-md-center'>
          <Col className='text-center'>
            <Image className='pb-4' src={proToolsPlaybackEngine} alt='Playback Engine' fluid />
          </Col>
        </Row>

        <p>
          <strong>Step 2</strong>
        </p>
        <p>
          Create a new session and open MIDI Input Devices under Setup/MIDI. Make sure loopMIDI Port or IAC Driver Bus is enabled.
        </p>
        <Row className='justify-content-md-center'>
          <Col className='text-center'>
            <Image className='pb-4' src={proToolsMidiInput} alt='MIDI Input' fluid />
          </Col>
        </Row>

        <p>
          <strong>Step 3</strong>
        </p>
        <p>
          Create a new mono audio track.
        </p>
        <Row className='justify-content-md-center'>
          <Col className='text-center'>
            <Image className='pb-4' src={proToolsCreateAudioTrack} alt='Create Audio Track' fluid />
          </Col>
        </Row>

        <p>
          <strong>Step 4</strong>
        </p>
        <p>
          Select the mono input from your audio interface with your mic connected as the audio input. 
          Insert Pitch Perfekt into the effects chain. 
          Remember to activate input monitoring to hear incoming audio.
        </p>
        <Row className='justify-content-md-center'>
          <Col className='text-center'>
            <Image className='pb-4' src={proToolsAudioTrack} alt='Audio Track' fluid />
          </Col>
        </Row>

        <p>
          <strong>Step 5</strong>
        </p>
        <p>
          Create a new stereo instrument track.
        </p>
          <Row className='justify-content-md-center'>
          <Col className='text-center'>
            <Image className='pb-4' src={proToolsCreateInstrumentTrack} alt='Create Instrument Track' fluid />
          </Col>
        </Row>

        <p>
          <strong>Step 6</strong>
        </p>
        <p>
          Insert a synthesiser or any virtual instrument of your choice into the track and activate input monitoring.
        </p>
        <Row className='justify-content-md-center'>
          <Col className='text-center'>
            <Image className='pb-4' src={proToolsInstrumentTrack} alt='Instrument Track' fluid />
          </Col>
        </Row>

        <p>
          <strong>Step 7</strong>
        </p>
        <p>
          Open the Mix Window and select the MIDI input source for the instrument track. 
          Possible choices are All, Pitch Perfekt Out and loopMIDI Port or IAC Driver Bus, respectively.
        </p>
        <Row className='justify-content-md-center'>
          <Col className='text-center'>
            <Image className='pb-4' src={proToolsInstrumentTrackInput} alt='Instrument Track Input' fluid />
          </Col>
        </Row>

        <p>
          <strong>Step 8</strong>
        </p>
        <p>
          Open Pitch Perfekt and
        </p>
        <ul>
          <li>set the line input to the channel associated with your audio input (only relevant for stereo input)</li>
          <ul>
            <li>Make sure the VU meter reacts to your input and reaches 100% without saturating.</li>
            <li>Use the gain knob on your audio interface and/or the plug-in to adjust the input level.</li>
            <li>Use the passthrough button to verify that your input is free of clicks and pops.</li>
          </ul>
          <li>set the MIDI output to the MIDI port that shall receive MIDI data from the plug-in:</li>
          <ul>
            <li>Use Default if you previously selected Pitch Perfekt Out as MIDI input for the instrument track.</li>
            <li>Use loopMIDI Port or IAC Driver Bus otherwise.</li>
          </ul>
        </ul>
        <Row className='justify-content-md-center'>
          <Col className='text-center'>
            <Image className='pb-4' src={proToolsPitchPerfekt} alt='Pitch Perfekt' fluid />
          </Col>
        </Row>

        <p>
          You should now be all set to start using Pitch Perfekt in Pro Tools.
        </p>
      </Container>
    </section>
  )
}

export default ProToolsUserGuide
