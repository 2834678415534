import React, { useEffect } from 'react'
import { Container } from 'react-bootstrap'
// import './style.scss'

const pitchPerfektUserGuideView = () => {
	window.gtag_sendEvent('user_guide/Pitch_Perfekt')
  window.fbq_customEvent('user_guide/Pitch_Perfekt')
}

const PitchPerfektUserGuide = () => {
  useEffect(() => pitchPerfektUserGuideView(), [])

  const today = new Date('March 25, 2025')
  
  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  }

  return (
    <div>
    <section id='user-guide'>
      <Container className='user-guide-text'>
        <h2>Pitch Perfekt</h2>
        <p>Last updated: {today.toLocaleDateString("en-SE", options)}</p>

        <p>
          Translating human voice pitch to MIDI can be more difficult than transcribing notes played on a string instrument for various reasons. 
          One such reason is that speech sounds can be both voiced and voiceless, or unvoiced, while so-called sonorants are most often modally voiced. 
          Sonorants are speech sounds that are produced with the vocal cords vibrating at a certain resonant frequency, which is associated with pitch. 
          Vowels are sonorants, but so also are semivowels like [j] and [w], nasal consonants like [m] and [n], and liquid consonants like [l] and [r]. 
          Even a sibilant like [z] is voiced, although not modally. 
          Modal voice is the vocal register used most frequently in singing and even though all sonorants participate in any pitch variation, 
          it is the pitch of the longer vowels that our ears pay most attention to when we hear a melody. 
          It is therefore a challenge for any pitch recognition algorithm to reliably sift out those sonorants that were produced with an open vocal tract. 
          Pitch Perfekt aims at striking a perfect balance between vowels and voiced consonants.
        </p>

        <div className='important-box'>
          <p>
            Pitch Perfekt uses the MIDI Tuning Standard with the A above middle C tuned to 440 Hz.
          </p>
        </div>

        <ul className='toc'>
          <li>
            <a href='#usage'>Usage</a>
            <ul>
              <li><a href='#gain'>Gain</a></li>
              <li><a href='#line-input'>Line input</a></li>
              <li><a href='#passthrough'>Passthrough</a></li>
              <li><a href='#harmonisation'>Harmonisation</a></li>
              <li><a href='#midi-output-channel'>MIDI output and channel</a></li>
              <li><a href='#scale-enforcement'>Scale enforcement</a></li>
              <li><a href='#dynamics'>Dynamics</a></li>
            </ul>
          </li>
          <li>
            <a href='#visualisation'>Visualisation</a>
            <ul>
              <li><a href='#vu-meter'>VU meter</a></li>
              <li><a href='#tuner'>Tuner</a></li>
              <li><a href='#spectrum-analyser'>Spectrum analyser</a></li>
              <li><a href='#keyboard'>Keyboard</a></li>
            </ul>
          </li>
          <li>
            <a href='#voice-to-midi'>Voice to MIDI</a>
            <ul>
              <li><a href='#real-time'>Real-time processing</a></li>
              <li><a href='#file'>File processing</a></li>
            </ul>
          </li>
          <li><a href="#version">Version and Ethics Statement</a></li>
          <li><a href="#technical-support">Technical Support</a></li>
        </ul>

        <h3 id='usage'>Usage</h3>
        <p>
          Here you can read up on how to use Pitch Perfekt.
        </p>

        <h4 id='gain'>Gain</h4>
        <p>
          Gain lets you increase the level of the selected line, i.e. the strength of the input signal. 
          It can be viewed as an additional preamp gain for the input signal. 
          You should aim your signal level to a target level of 0 VU or 100%.
        </p>

        <h4 id='line-input'>Line input</h4>
        <p>
          Line input lets you select the input channel of your audio device that is connected to your microphone.
        </p>

        <h4 id='passthrough'>Passthrough</h4>
        <p>
          The passthrough can be used to detect if there are any clicks or pops in the input signal, 
          which may deteriorate the voice analysis, and also to co-use Pitch Perfekt together with other audio plug-ins in one and the same effects chain. 
          Since the input signal passes through almost unaltered, make sure to prevent any unwanted audio feedback. 
          Use the P button to switch passthrough on or off.
        </p>
        
        <h4 id='harmonisation'>Harmonisation</h4>
        <p>
          The harmonisation feature requires the scale to be fixed. 
          It allows you to create a chordal accompaniment or vocal harmony from your sung melody using a harmonised version of the scale. 
          All chord factors are highlighted on the keyboard, 
          and the chords are indicated with Roman numerals representing scale degrees and chord symbols. 
          To enable harmonisation, click on the H button.
        </p>

        <h4 id='midi-output-channel'>MIDI output and channel</h4>
        <p>
          There is no common protocol for how a DAW may handle a virtual MIDI device including 
          the data it sends. We have therefore put in place two different methods of doing it. 
          The first method, which is the default, uses internal MIDI messaging between the 
          plug-in and the DAW. As this may not always lead to success, we have included another 
          method. The second method allows you to explicitly select a virtual MIDI output device 
          from the list of available devices. This method is less obscure, but may require some 
          additional effort when it comes to installing or enabling such a device. The standalone 
          app will use the MIDI output device from the MIDI Settings, if you opt for Default.
        </p>
        <p>
          The MIDI channel is useful when you run several plug-in instances at the same time and 
          wish to associate each of them with a different virtual instrument.
        </p>

        <h4 id='scale-enforcement'>Scale enforcement</h4>
        <p>
          If you wish to enforce a certain scale, you can do so by selecting Custom and specifying the mode and the tonic of the scale. 
          If you do not know the scale, you can select Automatic and the plug-in will determine the key of the input signal and set the scale for you. 
          It may take several tens of seconds before the key locks. 
          Notes outside of the scale are reassigned to other tones within the scale in a reactive manner, thus performing pitch correction.
        </p>

        <h4 id='dynamics'>Dynamics</h4>
        <p>
          The dynamics knob gives you full control over vocal dynamics and velocity. 
          A value of 1 will preserve the dynamics and the velocity of your singing, 
          while a value of 0 will bring the velocity up to its maximum, squashing the dynamic range to zero. 
          Values between the two extremes allow you to adjust the vocal dynamics and the velocity according to your preference.
        </p>

        <h3 id='visualisation'>Visualisation</h3>
        <p>
          Pitch Perfekt comes with a VU meter, a tuner and a spectrum analyser.
        </p>

        <h4 id='vu-meter'>VU meter</h4>
        <p>
          The built-in VU meter indicates the input signal level. 0 VU mean 0 dB RMS and the scale ranges from &minus;20 to +5 VU. 

        </p>

        <h4 id='tuner'>Tuner</h4>
        <p>
          The built-in tuner indicates the instantaneous frequency deviation in cents of human 
          voice pitch from the center frequency of the musical note based on A440. 
          The pitch difference is limited to &plusmn;100 cents with negative values indicating 
          that the input pitch is lower and positive values indicating that the input pitch is 
          higher than the desired pitch. A value of zero indicates that the input pitch is equal 
          to the desired pitch. The tuning accuracy is ±1 cent.
        </p>
        <p>
          The variation of voice pitch is also an indicator of pitch accuracy or intonation. 
          The sung note is in tune with the musical note in equal temperament when the deviation is zero. 
          Negative or positive deviations indicate that the sung note is flat or sharp, respectively.
        </p>

        <h4 id='spectrum-analyser'>Spectrum analyser</h4>
        <p>
          The built-in spectrum analyser shows the magnitude of the input signal versus logarithmic frequency roughly within the frequency range of wideband voice. 
          It enables you to see dominant frequency, noise, distortion, harmonics, bandwidth and other spectral components of the input signal. 
          The signal spectrum is visible only when the plug-in is active.
        </p>

        <h4 id='keyboard'>Keyboard</h4>
        <p>
          The keyboard displays keys corresponding to the twelve notes of the Western musical scale that repeats at the interval of an octave. 
          The twelve notes are laid out with the lowest note on the left being C. Each of the twelve keys thus represent a pitch class or chroma.
        </p>
        <p>
          A highlighted key is color coded by brightness to indicate intonation. The brighter the color the sharper the intonation and vice versa.
        </p>

        <h3 id='voice-to-midi'>Voice to MIDI</h3>
        <p>
          Listed below are the common use cases for Pitch Perfekt.
        </p>
        
        <h4 id='real-time'>Real-time processing</h4>
        <p>
          Once you have set up Pitch Perfekt, you can start transcribing your voice to MIDI with a simple click on the green button with the robot head. 
          The button will turn magenta on activation and the clock will start counting. 
          The scale settings including harmonisation are inaccessible during transcription. 
          When active, the plug-in recognises speech patterns and translates voiced speech sounds to MIDI events. 
          These are then sent to your DAW via the selected MIDI output and channel.
        </p>
        <ul>
          <li>The signal level is measured in dB RMS.</li>
          <li>The key can be major or minor.</li>
          <li>Chords are represented by Roman numerals and chord symbols.</li>
          <li>Scientific pitch notation is used to indicate musical pitch.</li>
        </ul>

        <h4 id='file'>File processing</h4>
        <p>
          You can also convert a voice recording to a Standard MIDI File by drag and drop:
        </p>
        <ol>
          <li>Select a WAVE, AIFF or MP3 file on your computer.</li>
          <li>Drag the audio file to the plug-in window and drop it over the green button with the robot head.</li>
        </ol>
        <p>
          This will initiate a conversion process and you will see its progress. 
          The current settings will be used during conversion. 
          On success, the resulting MIDI file will appear in the file manager next to the original audio file. 
          You can also use the standalone app for this, since latency does not matter here. 
          The following tracks will be written to the MIDI file:
        </p>
        <ol>
          <li>Time signature</li>
          <li>Tempo</li>
          <li>Key signature</li>
          <li>Notes</li>
        </ol>

        <h3 id='version'>Version and Ethics Statement</h3>
        <p>
          To see the version of Pitch Perfekt, click on the icon in the menu bar or the system tray and select About Pitch Perfekt from the context menu. 
          Alternatively, select Ethics Statement from the context menu to read where we stand in terms of human artistry and AI.
        </p>
        
        <h3 id='technical-support'>Technical Support</h3>
        <p>
          We do not provide technical support for Pitch Perfekt. To report a bug, click on the corresponding icon in the upper-right corner.
        </p>
      </Container>
    </section>
    </div>
  )
}

export default PitchPerfektUserGuide
