import React from 'react'
import {
	Container, Row, Col, Image,
} from 'react-bootstrap'
// import 'pure-react-carousel/dist/react-carousel.es.css'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faVideo } from '@fortawesome/free-solid-svg-icons'
// import VideoPlayer from '../../VideoPlayer'
// import FormTitle from '../../common/FormTitle'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
	faFileAudio,
	faGuitar,
	faHandSparkles,
	faMicrophoneLines,
	faMusic,
	faRadio,
	faSliders,
	faStopwatch } from '@fortawesome/free-solid-svg-icons'
import { images } from '../../../assets'
import './style.scss'

// const { audioPlugin, kvrAudio } = images
const { transkrV2Gui, polyphonicTunerGui, fretboardGui } = images

const TranskrV2Features = () => (
	<section id='transkr-v2-features'>
		<Container>
			{/* <FormTitle text='Audio Plugin' /> */}
			<Row className='plugin-p' /* align-items-center' */>
				<Col lg={6} md={10} className='m-md-auto plugin-img' /*text-center'*/>
					<Image src={transkrV2Gui} alt='Transkr V2' fluid />
					{/*
					<div className='plugin-overlay'>
						<VideoPlayer url='https://youtu.be/pddT15Dd0tc'>
							<div className='plugin-button'>
								<FontAwesomeIcon icon={faVideo} />
							</div>
						</VideoPlayer>
					</div>
					*/}
				</Col>
				<Col lg={6} className='plugin-text'>
					<h2>TRANSKR V2</h2>
					<h3>Instant Music Transcription</h3>
					<p className='plugin-p-padding'>
						Turn your instrument into a MIDI controller
					</p>
					<p><FontAwesomeIcon icon={faMicrophoneLines} />Polyphonic pitch detection</p>
					<p><FontAwesomeIcon icon={faMusic} />Beat, key, chord and note tracking</p>
					<p><FontAwesomeIcon icon={faGuitar} />Multiple instruments</p>
					<p><FontAwesomeIcon icon={faSliders} />Model customisation and fine-tuning</p>
					<p><FontAwesomeIcon icon={faRadio} />Polyphonic tuner</p>
					<p><FontAwesomeIcon icon={faHandSparkles} />Fretboard with easy fingerings</p>
					<p><FontAwesomeIcon icon={faStopwatch} />Low latency</p>
					<p><FontAwesomeIcon icon={faFileAudio} />File conversion</p>
				</Col>
			</Row>
			<Row>
				<Col lg={6} md={10} className='m-md-auto'>
					<Image src={polyphonicTunerGui} alt='Polyphonic Tuner' fluid />
				</Col>
			</Row>
			<Row>
				<Col>
					<Image src={fretboardGui} alt='Fretboard' fluid />
				</Col>
			</Row>
		</Container>
	</section>
)

export default TranskrV2Features
