import React, { useEffect } from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import { images } from '../../../assets'
// import './style.scss'

const {
  logicProAudioDevices,
	garageBandSettingsAudioMidi,
	garageBandCreateNewTrackMicOrLine,
	garageBandAudio1PlugIns,
	garageBandCreateNewTrackMidi,
	garageBandSoftwareInstrumentGuitar,
	garageBandTranskrV2
} = images

const garageBandUserGuideView = () => {
	window.gtag_sendEvent('user_guide/GarageBand')
  window.fbq_customEvent('user_guide/GarageBand')
}

const GarageBandUserGuide = () => {
  useEffect(() => garageBandUserGuideView(), [])

  return (
    <section id='user-guide'>
      <Container className='user-guide-text'>
        <h2>GarageBand</h2>

        <p>
          <strong>Step 1</strong>
        </p>
        <p>
          Open the Audio MIDI Setup app and select your audio interface. 
          Set the sampling rate to 48 kHz.
        </p>
        <Row className='justify-content-md-center'>
          <Col lg={8} className='text-center'>
            <Image src={logicProAudioDevices} alt='Audio Devices' fluid />
          </Col>
        </Row>

        <p>
          <strong>Step 2</strong>
        </p>
        <p>
          Open the audio/MIDI settings and select your audio interface as the audio device. 
          Make sure there is at least one MIDI input detected.
        </p>
        <Row className='justify-content-md-center'>
        <Col lg={8} className='text-center'>
          <Image src={garageBandSettingsAudioMidi} alt='Audio/MIDI Settings' fluid />
          </Col>
        </Row>

        <p>
          <strong>Step 3</strong>
        </p>
        <p>
          Create an audio track and choose the mono input from your audio interface with your mic or instrument connected as the audio input.
        </p>
        <Row className='justify-content-md-center'>
        <Col lg={12} className='text-center'>
          <Image src={garageBandCreateNewTrackMicOrLine} alt='Create Audio Track' fluid />
          </Col>
        </Row>

        <p>
          <strong>Step 4</strong>
        </p>
        <p>
          Select the audio track and add Transkr V2 to audio effects.
        </p>
        <Row className='justify-content-md-center'>
        <Col lg={12} className='text-center'>
          <Image src={garageBandAudio1PlugIns} alt='Audio Plug-ins' fluid />
          </Col>
        </Row>

        <p>
          <strong>Step 5</strong>
        </p>
        <p>
          Create a MIDI track with a software instrument for audio output.
        </p>
        <Row className='justify-content-md-center'>
        <Col lg={12} className='text-center'>
          <Image src={garageBandCreateNewTrackMidi} alt='Create MIDI Track' fluid />
          </Col>
        </Row>

        <p>
          <strong>Step 6</strong>
        </p>
        <p>
          Select a synthesiser or any virtual instrument of your choice from the library. 
          The selected instrument will receive MIDI data from Transkr V2 via the MIDI port.
        </p>
        <Row className='justify-content-md-center'>
        <Col lg={12} className='text-center'>
          <Image src={garageBandSoftwareInstrumentGuitar} alt='Software Instrument' fluid />
          </Col>
        </Row>

        <p>
          <strong>Step 7</strong>
        </p>
        <p>
          Open Transkr V2 and
        </p>
        <ul>
          <li>
            set the line input to the channel associated with your audio input
          </li>
          <ul>
            <li>
              Make sure the VU meter reacts to your input and reaches 100% without saturating.
            </li>
            <li>
              Use the gain knob on your audio interface and/or the plug-in to adjust the input level.
            </li>
            <li>
              Use the &quot;pass thru&quot; button to verify that your input is free of clicks and pops.
            </li>
          </ul>
          <li>
            set the MIDI output to the MIDI port that shall receive MIDI data from the plug-in
          </li>
          <li>
            select one of the defaults from the preset list that best represents your instrument
          </li>
        </ul>
        <Row className='justify-content-md-center'>
        <Col lg={8} className='text-center'>
          <Image src={garageBandTranskrV2} alt='Transkr V2 GUI' fluid />
          </Col>
        </Row>

        <p>
          You should now be all set to start using Transkr V2 in GarageBand.
        </p>
      </Container>
    </section>
  )
}

export default GarageBandUserGuide
