import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleUp } from '@fortawesome/free-solid-svg-icons'
import './style.scss'

const BackToTop = () => {
	const [hide, setHide] = useState(true)

	const handleScroll = () => setHide(window.scrollY < 50)

	useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

	const scrollToTop = () => window.scrollTo({ top: 0, behavior: 'smooth' })

	return (
		<div className={`back-to-top ${hide ? 'fade-out' : 'fade-in'}`} onClick={scrollToTop}>
			<FontAwesomeIcon icon={faAngleUp} />
		</div>
	)
}

export default BackToTop
