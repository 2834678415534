import React from 'react'
import {
	Container, Row, Col, Image,
} from 'react-bootstrap'
// import 'pure-react-carousel/dist/react-carousel.es.css'
import VideoPlayer from '../../VideoPlayer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
	faCreditCard,
	faFileAudio,
	faMicrophoneLines,
	faMusic,
	faPlay,
	faStopwatch,
	faUsers,
	faWandMagicSparkles } from '@fortawesome/free-solid-svg-icons'
import { images } from '../../../assets'
import './style.scss'

const { pitchPerfektGui } = images

const pluginVideoUrl = 'https://youtu.be/kKDSsxRxdOE'

const pluginVideoPlayed = () => {
	window.gtag_sendEvent('pitch_perfekt_video_play', {
		url: pluginVideoUrl
	})

	window.fbq_customEvent('pitch_perfekt_video_play', {
		url: pluginVideoUrl
	})
}

const PitchPerfektFeatures = () => (
	<section id='pitch-perfekt-features'>
		<Container>
			<Row className='plugin-p'>
				<Col lg={6} md={10} className='m-md-auto plugin-img'>
					<Image src={pitchPerfektGui} alt='Pitch Perfekt' fluid />
					<div className='plugin-overlay'>
						<VideoPlayer url={pluginVideoUrl} onPlay={pluginVideoPlayed}>
							<div className='plugin-button'>
								<FontAwesomeIcon icon={faPlay} />
							</div>
						</VideoPlayer>
					</div>
				</Col>
				<Col lg={6} className='plugin-text'>
					<h2>PITCH PERFEKT</h2>
					<h3>Never Hit a Wrong Note Again</h3>
					<p className='plugin-p-padding'>
						Turn your voice into a MIDI controller
					</p>
					<p><FontAwesomeIcon icon={faMicrophoneLines} />Pitch detection</p>
					<p><FontAwesomeIcon icon={faMusic} />Key and note tracking</p>
					<p><FontAwesomeIcon icon={faWandMagicSparkles} />Scale enforcement</p>
					<p><FontAwesomeIcon icon={faUsers} />Harmonisation</p>
					<p><FontAwesomeIcon icon={faStopwatch} />Low latency</p>
					<p><FontAwesomeIcon icon={faFileAudio} />File conversion</p>
					<p><FontAwesomeIcon icon={faCreditCard} />Free of charge</p>
				</Col>
			</Row>
		</Container>
	</section>
)

export default PitchPerfektFeatures
