import React, { useMemo, useState } from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuoteLeft } from '@fortawesome/free-solid-svg-icons'
import { images } from '../../../assets'
import './style.scss'

const {
  benjaminImage,
	eduardoImage,
	juanCarlosImage,
	leoImage,
	luisImage,
	troyImage,
  viktorImage
} = images

const customers = [{
  id: 0,
  name: 'Benjamin',
  nickname: 'Ben',
  profession: 'Musician and Music Producer',
  quote: 'I usually play around with Transkr V2, making something unconventional. I discover new ideas every time. It is really fun! I will definitely create more with it in the future.',
	headshot: benjaminImage
}, {
  id: 1,
  name: 'Eduardo',
  nickname: 'Lalo',
  profession: 'Singer-Songwriter',
  quote: 'The Transkr V2 plug-in is amazing! It\'s really fun to play and experiment with, and do everything that I usually do with a MIDI controller and my guitar.',
	headshot: eduardoImage
}, {
  id: 2,
  name: 'Juan Carlos',
  nickname: 'Juan Carlos',
  profession: 'Bass Player and Composer',
  quote: 'There are days when I experience a creative slowdown and wish for a little help. Transkr V2 helps me overcome such moments of inspirational low.',
	headshot: juanCarlosImage
}, {
  id: 3,
  name: 'Leo',
  nickname: 'Leo',
  profession: 'Musician and Composer',
  quote: 'Creating a track from scratch with Transkr V2 is both unusual and intricate. It provides its own perspective on harmony and melody, and stimulates me to think outside of the box.',
	headshot: leoImage
}, {
  id: 4,
  name: 'Luis',
  nickname: 'Luis',
  profession: 'Singer-Songwriter and Music Producer',
  quote: 'It\'s wonderful! I no longer need to think about how to put my musical ideas to paper. I just sing a random melody and Transkr V2 does the writing for me.',
	headshot: luisImage
}, {
  id: 5,
  name: 'Troy',
  nickname: 'Troy',
  profession: 'Electronic Music Producer',
  quote: 'It took me a little while to grasp it, but when it clicked, I saw how useful it was. Transkr V2 helps me figure out scales and harmony, and I can drag MIDI data directly into my DAW.',
	headshot: troyImage
}, {
  id: 6,
  name: 'Viktor',
  nickname: 'Viktor',
  profession: 'Musician and Bandleader',
  quote: 'Why spend hours transcribing songs by ear when you can have Transkr V2 do the heavy lifting? Now I can focus on making live music instead of writing sheet music. Love it!',
	headshot: viktorImage
}];

const Testimonial = () => {
  const [nav1, setNav1] = useState(null)
  const [nav2, setNav2] = useState(null)

  const settings1 = {
    className: 'testimonial-main',
    slidesToShow: 1,
		slidesToScroll: 1,
		// autoplay: true,
		fade: true,
		// speed: 600,
		dots: false,
		arrows: false
  }

  const settings2 = {
    className: 'testimonial-main-img',
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    // autoplaySpeed: 1500,
    dots: false,
    arrows: false,
    centerMode: true,
    centerPadding: '0',
    pauseOnHover: true
  }

  const sliderQuotes = useMemo(() => customers.map(customer =>
    <div key={customer.id} className='testimonial-item text-center'>
      <p>{customer.quote}</p>
      <h3>{customer.nickname}.</h3>
      <span>{customer.profession}</span>
    </div>
  ), [])

  const sliderHeadshots = useMemo(() => customers.map(customer =>
    <div key={customer.id} className='testimonial-img-item text-center'>
      <Image src={customer.headshot} alt={customer.name} fluid />
    </div>
  ), [])

  return (
    <section id='testimonial'>
      <Container>
        {/*
        <Row>
          <Col lg={12} className='text-center overview-head'>
            <h2>TESTIMONIALS</h2>
          </Col>
        </Row>
        */}
        <Row>
          <Col lg={12} className='text-center'>
            <FontAwesomeIcon icon={faQuoteLeft} />
            <Slider {...settings1} ref={(slider) => setNav1(slider)} asNavFor={nav2}>
              {sliderQuotes}
            </Slider>
          </Col>
        </Row>
        <Row>
          <Col lg={3} className='m-auto'>
            <Slider {...settings2} ref={(slider) => setNav2(slider)} asNavFor={nav1}>
              {sliderHeadshots}
            </Slider>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Testimonial
